import React from 'react'
import "../styles/global.css";
import styles from"../styles/contact.module.css"
import ContactForm from '../components/website_components/ContactForm/ContactForm.js'
import BeckLogoShadow3D from "../images/beck-logo-3d-shadow.png"
import NoHeroLayout from "../layouts/NoHeroLayout"
import 'animate.css/animate.css' 

const ContactPage = () => (
  <NoHeroLayout seo={{ title: "Contact Us",  url: "https://beckcollegeprep.com/contact" }}>
    <div className={`${styles.page} d-flex justify-content-center`} style={{ flex : 1 }}>
      <img alt="Beck College Prep Logo" className={`${styles.logo} animate__animated animate__fadeIn animate__slow`} src={BeckLogoShadow3D}></img>
      
      <div className={`${styles.contact} animate__animated animate__fadeInUp`}>
          <h1 className={`${styles.heading}`}>Contact Us</h1>
          <p className={`${styles.sub}`}>Call us at (305) 562-3814 or fill out the form below and we’ll contact you as soon as possible:</p>
          <ContactForm></ContactForm>
      </div>
    </div>
  </NoHeroLayout>
)

export default ContactPage