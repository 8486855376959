import React, { useState, useEffect } from 'react';
import styles from './ContactForm.module.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { sendContactUsEmail } from '../../../api/api';
const ContactForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmit] = useState(false);

    function submitContactUsForm(e) {
        e.preventDefault();
        setSubmit(true);
        const data = {
            firstName,
            lastName,
            phoneNumber,
            email,
            subject,
            message
        };

        sendContactUsEmail(data).then(res => {
            alert(res.message);

        })

    }

    return (
        <div className = 'm-3'>
            <Form id='contact' onSubmit={(e) => submitContactUsForm(e)}>
                <Form.Row>
                    <Col xs = {12} sm = {6}>
                        <Form.Group>
                            <Form.Control className = {`${styles.control} ${styles.box}`} name='First Name' placeholder="First Name" required 
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col xs = {12} sm = {6}>
                        <Form.Group>
                            <Form.Control className = {`${styles.control} ${styles.box}`} name='Last Name' placeholder="Last Name"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}/>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col xs = {12} sm = {6}>
                        <Form.Group>
                            <Form.Control className = {`${styles.control} ${styles.box}`} name='Phone Number' placeholder="Phone Number" 
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col xs = {12} sm = {6}>
                        <Form.Group>
                            <Form.Control className = {`${styles.control} ${styles.box}`} name='E-mail' type="email" placeholder="E-mail" required
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}/>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col sm = {12}>
                        <Form.Group >
                            <Form.Control className = {`${styles.control} ${styles.dropdown}`} name='subject' required as="select" 
                                value={subject}
                                onChange={(event) => setSubject(event.target.value)}>
                                <option value='Website Contact'>Type of Inquiry</option>
                                <option value='General Inquiry'>General Inquiry</option>
                                <option value='Free Consultation'>Free Consultation</option>
                                <option value='Test Prep'>Test Prep</option>
                                <option value='Admision Prep'>Admission Prep</option>
                                <option value='Study Skills'>Study Skills</option>
                                <option value='Subject Skills'>Subject Skills</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Group >
                    <Form.Control className = {`${styles.control} ${styles.text}`} as='textarea' name='Message' rows='5' placeholder="Message" 
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}/>
                </Form.Group>
                <Row className='justify-content-end'>
                    <Col xs={6} sm={3} >
                        {submitted ?
                            <Button type='submit'  disabled className={`${styles.bcpButton} btn-block`} >
                                <span>Sent!</span>
                            </Button>
                        :
                            <Button type='submit' className={`${styles.bcpButton} btn-block contact-submit`} >
                                <span>Submit</span>
                            </Button>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ContactForm;
